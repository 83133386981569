var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Main',[_c('v-row',{staticClass:"max-w-500",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"changePasswordForm"},[_c('CUTextField',{attrs:{"id":"my-profile-password-text-old-password","label":"Old Password","error-messages":_vm.oldPasswordErrors,"rules":_vm.rules.required('Old password is required'),"type":"password","required":"","outlined":""},model:{value:(_vm.formFields.oldPassword),callback:function ($$v) {_vm.$set(_vm.formFields, "oldPassword", $$v)},expression:"formFields.oldPassword"}}),_c('CUTextField',{attrs:{"id":"my-profile-password-text-new-password","label":"New Password","rules":[
            (v) => !!v || 'Please enter a new password',
            (v) =>
              v.length >= 3 || 'New password must be at least 3 characters',
          ],"type":"password","required":"","outlined":""},model:{value:(_vm.formFields.newPassword),callback:function ($$v) {_vm.$set(_vm.formFields, "newPassword", $$v)},expression:"formFields.newPassword"}}),_c('CUTextField',{attrs:{"id":"my-profile-password-text-confirm-new-password","label":"Confirm New Password","rules":[
            (v) => !!v || 'Please confirm your password',
            (v) => v === _vm.formFields.newPassword || 'New passwords must match',
          ],"type":"password","required":"","outlined":""},model:{value:(_vm.formFields.confirmNewPassword),callback:function ($$v) {_vm.$set(_vm.formFields, "confirmNewPassword", $$v)},expression:"formFields.confirmNewPassword"}}),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"text":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'quotes' })}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"small":"","color":"primary","disabled":_vm.disabled,"width":"150px"},on:{"click":_vm.submit}},[(!_vm.submitting)?[_vm._v("Change Password")]:_c('span',{staticClass:"padding-x-3"},[_c('v-progress-circular',{attrs:{"size":10,"width":2,"color":"white","indeterminate":""}})],1)],2)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }