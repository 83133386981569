
import Main from '@/layouts/Main.vue'
import user from '@/services/user'
import { Vue, Component, Watch } from 'vue-property-decorator'
import auth from '@/store/modules/auth'
import { isNotEmptyInput } from '@/utils/validators'

@Component({ components: { Main } })
export default class ChangePassword extends Vue {
  formFields: Record<string, string> = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  }
  submitting = false
  disabled = false

  oldPasswordErrors = ''

  @Watch('formFields', { deep: true })
  onFormUpdate(): void {
    this.oldPasswordErrors = ''
    this.disabled = false
  }

  rules = {
    required: (message: string): ((_: string) => boolean | string)[] => [
      (value: string): boolean | string => isNotEmptyInput(value) || message,
    ],
    length: (message: string): ((_: string) => boolean | string)[] => [
      (value: string): boolean | string =>
        (value || '').length <= 500 || message,
    ],
  }

  async submit(): Promise<void> {
    const form: any = this.$refs['changePasswordForm']

    if (!form.validate()) {
      return
    }

    this.submitting = true

    await user
      .changePassword(
        auth.getUserId,
        this.formFields.newPassword,
        this.formFields.oldPassword
      )
      .then(() => {
        // TODO alert success
        this.$router.push({ name: 'quotes' })
      })
      .catch((error) => {
        // TODO alert failure
        this.disabled = true
        this.oldPasswordErrors = error.response.data.description
      })

    this.submitting = false
  }
}
